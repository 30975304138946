import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);
const store = new Vuex.Store({
	state: {
		topHeaderActiveTabName: "/index",
		baseURL: "https://yuqing.sdcdtz.com/api/"
	}
})
export default store