import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
import Layout from '@/layout'

export const constantRoutes = [
{
	path: '/',
	name: '登录页',
	component: () => import('@/views/login')
},
{
    path: '/index',
    component: Layout,
    redirect: 'index',
    children: [
		{
			path: '/index',
			component: () => import('@/views/index'),
			name: 'Index',
			meta: { title: '首页' }
		}
    ]
},
{
	path: '/monitor/list',
	component: Layout,
	redirect: 'monitor',
	children: [
		{
		path: '/monitor/list',
		component: () => import('@/views/monitor/list'),
		name: 'monitor',
		meta: { title: '舆情监测' }
		}
	]
},
{
	path: '/warn/list',
	component: Layout,
	redirect: 'warn',
	children: [
	    {
			path: '/warn/list',
			component: () => import('@/views/warn/list'),
			name: 'warn',
			meta: { title: '舆情预警' }
	    }
	]
},{
	path: '/report/list',
	component: Layout,
	redirect: 'report',
	children: [
	    {
			path: '/report/list',
			component: () => import('@/views/report/list'),
			name: 'report',
			meta: { title: '舆情看板' }
	    }
	]
},{
	path: '/search/list',
	component: Layout,
	redirect: 'search',
	children: [
	    {
			path: '/search/list',
			component: () => import('@/views/search/list'),
			name: 'search',
			meta: { title: '全文检索' }
	    }
	]
}];

const createRouter = () =>
  new Router({
    mode: "hash", // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

export default router;
