import axios from 'axios'
import store from "@/store"
import Element from 'element-ui'


// 创建axios实例
const customAxios = axios.create({
	baseURL: store.state.baseURL,
	method: "POST",
	timeout: 60000,
});


customAxios.interceptors.request.use(function(config) {
	config.headers["token"] = sessionStorage.getItem("token");
		return config;
}, function(error) {
		return Promise.reject(error);
});



customAxios.interceptors.response.use(function(response) {
	if (response.status != 200) {
		Element.Message({
			message: response.data.msg,
			type: 'error'
		});
	}
	

	return response;
});

export default customAxios
