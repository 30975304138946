<template>
  <div class="top-header">
		<el-row>
			<el-col :span="6">
				<div class="top-logo">
					省联社济宁审计中心
				</div>
			</el-col>
			<el-col :span="13">
				<el-tabs v-model="$store.state.topHeaderActiveTabName" @tab-click="toggleTab" class="menu-tab-wrapper">
					<el-tab-pane
						ref="menuTab"
						:key="menu.path"
						v-for="(menu, index) in menuTabs"
						:label="menu.name"
						:name="menu.path"
					>
						<template slot="label">
							<div class="menu-tab-item" v-if="menu.blank">
								{{ menu.name }}
							</div>
							<router-link
								v-else
							  ref="menu"
							  :to="{ path: menu.path }"
							  tag="div"
							  class="menu-tab-item"
							>
							{{ menu.name }}
							</router-link>
						</template>
					</el-tab-pane>
				</el-tabs>
			</el-col>
			<el-col :span="5">
				<el-dropdown class="dropdown-item-wrapper" trigger="click" @command="handleCommand">
				  <span class="el-dropdown-link cp">
				    {{userInfo.nickname}}<i class="el-icon-arrow-down el-icon--right"></i>
				  </span>
				  <el-dropdown-menu slot="dropdown">
				    <!-- <el-dropdown-item>个人中心</el-dropdown-item> -->
				    <el-dropdown-item command="signOut">退出</el-dropdown-item>
				  </el-dropdown-menu>
				</el-dropdown>
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	name: 'topHeader',
	data() {
		return{
			userInfo: JSON.parse(sessionStorage.getItem("user_info")),
			menuTabs: [{
				"name": "首页",
				"path": "/index"
			},{
				"name": "舆情监测",
				"path": "/monitor/list"
			},{
				"name": "舆情预警",
				"path": "/warn/list"
			},{
				"name": "舆情报告",
				"path": "/report/list"
			},{
				"name": "全文检索",
				"path": "/search/list"
			},{
        "name": "舆情看板",
        "blank": true,
        "path": "https://yuqing.sdcdtz.com/screen/index.html"
      }]
		}
	},
	computed: {

	},
	mounted() {
		this.$nextTick(() =>{
			this.$store.state.topHeaderActiveTabName = this.$route.path;
		})
	},
	methods: {
		toggleTab(val) {
			if (val.label == "舆情看板") {
				window.open("https://yuqing.sdcdtz.com/screen/index.html","_blank")
			}
		},
		handleCommand(command) {
			if (command === 'signOut') {
				this.signOut()
			}
		},
		signOut() {
			sessionStorage.clear();
			this.$router.push('/');
		},
	}
}
</script>

<style lang="scss">
	.top-header{
		color:#333;
		height:60px;
		padding:0 20px;
		line-height: 60px;
		background:#fff;
		box-shadow: 0px 2px 3px 0px rgba(231,230,232,0.99);
		.top-logo{
			color:#0A81DD;
			font-size:18px;
			padding-left:70px;
			background:url(../../assets/images/logo.jpg) no-repeat left center;
			background-size:auto 60px;
		}
		.menu-tab-wrapper{
			.el-tabs__header{
				height:60px;
				margin-bottom:0;
				.el-tabs__item{
					font-size:16px;
					padding:0;
					.menu-tab-item{
						padding:0 20px;
					}
				}
			}
			.el-tabs__nav-wrap:after{
				background:#fff;
			}
		}

		.dropdown-item-wrapper{
			float:right;
		}
	}
</style>
