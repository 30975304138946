<template>
	<el-container class="h100p" id="app-wrapper">
		<el-header>
			<top-header ref="topHeader"></top-header>
		</el-header>
		<el-main class="main-content">
			<transition name="fade-transform" mode="out-in">
				<keep-alive>
					<router-view />
				</keep-alive>
			</transition>
		</el-main>
	</el-container>
</template>

<script>
import { topHeader } from './components'
export default {
	name: 'Layout',
	components: {
		topHeader
	},
	computed: {
	},
	methods: {
	}
}
</script>

<style lang="scss" scoped>
	#app-wrapper{
		.el-header{
			padding:0;
		}
		.main-content{
			height:calc(100% - 60px);
			overflow: hidden;
			// overflow-y: auto;
			padding:0px;
			background:#F3F2F4;
		}
	}
	
</style>
