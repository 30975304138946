import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/styles/global.css'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
Vue.use(ElementUI);

//自定义Axios
import $axios from './utils/axios'
Vue.prototype.$axios = $axios;

Vue.config.productionTip = false

import * as echarts from 'echarts'

Vue.prototype.$echarts = echarts //挂载到Vue实例上面

new Vue({
	router,
	store,
  render: h => h(App),
}).$mount('#app')
